import { Console } from "vue-router";

import ServiceViewport from "@Core/Components/ServiceViewport/ServiceViewport.vue";
import Viewport from "@Core/Components/Viewport/Viewport.vue";

import AuthView from "@Module/Auth/Views/AuthView.vue";

import ConsoleHub from './Views/Hub/ConsoleHub.vue';
import UsersManager from "@Module/Users/Views/UsersManager/UsersManager.vue";
import LicensingSummary from "@Module/Licensing/Views/LicensingSummary/LicensingSummary.vue";
import UserDetails from "@Module/Users/Views/UserDetails/UserDetails.vue";
import NotFoundView from "@Core/Components/Views/NotFound/NotFoundView.vue";
import ForbiddenView from "@Core/Components/Views/Forbidden/ForbiddenView.vue";
import FinancialReportView from "@Module/Reports/Views/Report/FinancialReportView.vue";
import ReportContractDetails from "@Module/Reports/Views/Report/ReportContractDetails/ReportContractDetails.vue";

const routes: Console.RouteConfig[] = [
  {
    path: "/cxlink/auth",
    name: "cxlink-auth",
    component: AuthView,
    meta: {
      signOutOnEnter: true,
      menu: {
        hidden: true,
        label: "Authentication",
        icon: {
          type: "ion-icon",
          name: "lock-closed"
        }
      }
    }
  },
  {
    path: "/cxlink",
    name: "cxlink",
    component: ServiceViewport,
    redirect: { name: "cxlink-home" },
    meta: {
      menu: {
        label: "CxLink",
        hidden: true,
        icon: {
          type: "custom",
          name: "icons/console.svg"
        }
      }
    },
    children: [
      {
        path: 'home',
        name: "cxlink-home",
        component: ConsoleHub,
        meta: {
          authRequired: true,
          menu: {
            label: "CxLink",
            icon: {
              type: "ion-icon",
              name: "bookmark"
            }
          }
        }
      },
      {
        path: "/cxlink/admin",
        name: "cxlink-admin",
        component: Viewport,
        redirect: { name: "cxlink-users-manager" },
        meta: {
          authRequired: true,
          breadcrumb: {
            hidden: true
          },
          menu: {
            label: "Administration",
            icon: {
              type: "ion-icon",
              name: "code-slash"
            }
          }
        },
        children: [
          {
            path: '/cxlink/users',
            name: "cxlink-users-manager",
            component: UsersManager,
            meta: {
              permissions: "console:ListAppUsers",
              authRequired: true,
              menu: {
                label: "Users Manager",
                groupId: "admin",
                icon: {
                  type: "ion-icon",
                  name: "people-circle-outline"
                }
              }
            }
          },
          {
            path: "/cxlink/users/:username",
            name: "cxlink-user-details",
            component: UserDetails,
            meta: {
              permissions: "console:ListAppUsers",
              authRequired: true,
              breadcrumb: {
                prependPaths: ["/cxlink/users"]
              },
              menu: {
                hidden: true,
                selectionPath: "/cxlink/users",
                label: "User Details",
                icon: {
                  type: "ion-icon",
                  name: "person-circle-outline"
                }
              }
            }
          },
          {
            path: '/cxlink/licensing-summary',
            name: "cxlink-licensing-summary",
            component: LicensingSummary,
            meta: {
              permissions: "console:ListLicensingSummary",
              authRequired: true,
              menu: {
                label: "Licensing Summary",
                groupId: "admin",
                icon: {
                  type: "ion-icon",
                  name: "id-card-outline"
                }
              }
            }
          },
          {
            path: '/cxlink/financial-report',
            name: "cxlink-financial-report",
            component: FinancialReportView,
            meta: {
              permissions: "console:ListFinancialInfo",
              authRequired: true,
              menu: {
                label: "Financial Report",
                groupId: "admin",
                icon: {
                  type: "ion-icon",
                  name: "card-outline"
                }
              }
            }
          },
          {
            path: "/cxlink/financial-report/:id",
            name: "report-contract-details",
            component: ReportContractDetails,
            meta: {
              permissions: "console:ListFinancialInfo",
              authRequired: true,
              breadcrumb: {
                prependPaths: ["/cxlink/financial-report"]
              },
              menu: {
                hidden: true,
                selectionPath: "/cxlink/financial-report",
                label: "Financial Report",
                icon: {
                  type: "ion-icon",
                  name: "card-outline"
                }
              }
            }
          }
        ]
      },
      {
        path: 'not-found',
        name: "cxlink-not-found",
        component: NotFoundView,
        meta: {
          menu: {
            label: "Page Not Found",
            hidden: true,
            icon: {
              type: "ion-icon",
              name: "search"
            }
          }
        }
      },
      {
        path: 'forbidden',
        name: "cxlink-fobidden",
        component: ForbiddenView,
        meta: {
          menu: {
            label: "Forbidden Page",
            hidden: true,
            icon: {
              type: "ion-icon",
              name: "lock-closed"
            }
          }
        }
      }
    ]
  }
];

export default routes
